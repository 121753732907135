import { GetHeartbeatBarChartDataQueryResult } from '@tapestry/shared/graphql';
import { THREAD_TYPE } from '@tapestry/types';

interface IHeartbeatChartBarResponse {
  [THREAD_TYPE.DEPARTMENT]: Partial<GetHeartbeatBarChartDataQueryResult>;
  [THREAD_TYPE.PRODUCT]: Partial<GetHeartbeatBarChartDataQueryResult>;
  [THREAD_TYPE.CATEGORY]: Partial<GetHeartbeatBarChartDataQueryResult>;
}

export const MOCK_HEARTBEAT_BAR_CHART: IHeartbeatChartBarResponse = {
  department: {
    data: {
      barChart: {
        data: [
          {
            id: '14d13633-e594-464d-8ec7-a6663c21d8a3',
            name: 'GROCERY',
            value: 9886.09,
            __typename: 'DataValueId',
          },
          {
            id: 'f4208304-da3f-4c20-b1f7-9c8c8fe23a15',
            name: 'DAIRY',
            value: 4539.48,
            __typename: 'DataValueId',
          },
          {
            id: 'bad53123-a73a-46e0-a23d-0e3d27324a91',
            name: 'LIQUOR',
            value: 3262.32,
            __typename: 'DataValueId',
          },
          {
            id: '6474e578-acb2-43ea-a0f4-98c48a27a733',
            name: 'TOBACCO',
            value: 2687.3,
            __typename: 'DataValueId',
          },
          {
            id: '775c35c7-6055-45d7-bb90-5779f8e16967',
            name: 'PRODUCE',
            value: 2492.41,
            __typename: 'DataValueId',
          },
          {
            id: '83bed798-9f77-44b8-915d-16a5c8497117',
            name: 'MEAT',
            value: 1328.18,
            __typename: 'DataValueId',
          },
          {
            id: '0b5aac10-a790-48e0-a2ed-b2ae90df481d',
            name: 'FROZEN',
            value: 877.43,
            __typename: 'DataValueId',
          },
          {
            id: '4dee4d38-0d21-445d-a4c6-b5ef1aa8a214',
            name: 'TAKEAWAY',
            value: 692.92,
            __typename: 'DataValueId',
          },
          {
            id: 'a6fc5183-078d-4ff9-9eb5-f63c8e9f213e',
            name: 'VARIETY',
            value: 385.99,
            __typename: 'DataValueId',
          },
          {
            id: 'aad27924-f1ef-4792-b6b4-9d72727d9915',
            name: 'NEWSAGENCY',
            value: 143,
            __typename: 'DataValueId',
          },
          {
            id: 'd3c6e6c1-4114-4976-8617-b29e3c178926',
            name: 'DELI',
            value: 104.19,
            __typename: 'DataValueId',
          },
        ],
        __typename: 'HeartbeatChartBarPaginatorType',
      },
    },
  },
  category: {
    data: {
      barChart: {
        data: [
          {
            id: '8e05c6ff-9fb6-48d4-b5cf-42c334076278',
            name: 'CIGARETTES, SLEEVES',
            value: 2169.3,
            __typename: 'DataValueId',
          },
          {
            id: 'ac479838-6fea-49ae-8757-9e8773b198f5',
            name: 'CONVENIENT MEAL OPTI',
            value: 1433,
            __typename: 'DataValueId',
          },
          {
            id: '0e027781-2940-4cf6-bc15-2a9bf456d794',
            name: 'VEGETABLES',
            value: 881.19,
            __typename: 'DataValueId',
          },
          {
            id: 'c902deaa-c80c-4d73-8386-5872f267959d',
            name: 'TAKEAWAY FOOD',
            value: 692.92,
            __typename: 'DataValueId',
          },
          {
            id: '5587e5c0-8f6f-4ada-b3c9-2c3715b94c92',
            name: 'CHOCOLATES',
            value: 683.85,
            __typename: 'DataValueId',
          },
          {
            id: '8b6fb9ee-d16f-4fe4-ae2a-6b097d30a46e',
            name: 'WINES RED - LOCAL',
            value: 650.98,
            __typename: 'DataValueId',
          },
          {
            id: '96f5ee4b-9e56-47bb-be80-b73bdb513302',
            name: 'MILK, FRESH',
            value: 620.72,
            __typename: 'DataValueId',
          },
          {
            id: 'a964c1cb-b4f9-415a-bdff-decccbcbdf23',
            name: 'BEEF',
            value: 586.18,
            __typename: 'DataValueId',
          },
          {
            id: '44a086db-6b89-4cf9-be30-4df966fc74dc',
            name: 'SNACKS AND SAVOURIES',
            value: 570.56,
            __typename: 'DataValueId',
          },
          {
            id: '2ea66d93-c7c7-4899-8a05-4af1a894f8a8',
            name: 'BISCUITS',
            value: 564.48,
            __typename: 'DataValueId',
          },
          {
            id: '4f3ec085-bb2f-4f54-9f31-9e6935955ba1',
            name: 'SALAD COMPONENTS',
            value: 537.47,
            __typename: 'DataValueId',
          },
          {
            id: '1d6839bc-1213-4a81-bfc6-fbd47ecb4741',
            name: 'TOBACCO',
            value: 518,
            __typename: 'DataValueId',
          },
          {
            id: '39b1c44e-91eb-41e8-b9d0-c83b341df8d8',
            name: 'BAKERY BREAD',
            value: 438.81,
            __typename: 'DataValueId',
          },
          {
            id: 'ba9fd193-b78e-4fff-9081-6bdd1223bec8',
            name: 'WINES WHITE - LOCAL',
            value: 407,
            __typename: 'DataValueId',
          },
          {
            id: 'a92112fe-7023-4b94-9b29-41f2f7ed42d9',
            name: 'CONFECTIONERY',
            value: 347.1,
            __typename: 'DataValueId',
          },
        ],
        __typename: 'HeartbeatChartBarPaginatorType',
      },
    },
  },
  product: {
    data: {
      barChart: {
        data: [
          {
            id: '5b65b4b6-df2f-4091-9533-0ea7987cf2a8',
            name: 'MARLBORO GOLD',
            value: 313.8,
            __typename: 'DataValueId',
          },
          {
            id: '9bb25bf1-d24a-4ccd-b276-37a06e88a26e',
            name: 'BOND STREET BLUE CLASSIC',
            value: 218.75,
            __typename: 'DataValueId',
          },
          {
            id: '2e79c61e-618b-44f3-b8c4-e49de045c6fa',
            name: 'DUNHILL ORIG DIST BLUE',
            value: 207.2,
            __typename: 'DataValueId',
          },
          {
            id: 'cc8d3f0f-84b6-4d3a-b805-eca01adaad26',
            name: 'WINFIELD ORIGINAL GOLD',
            value: 173.6,
            __typename: 'DataValueId',
          },
          {
            id: '9b528257-dc24-4fbf-8bfb-0eaac95c85ef',
            name: 'GROCERY NON SCAN',
            value: 168.98,
            __typename: 'DataValueId',
          },
          {
            id: '6c377820-6e85-43db-b9a3-343b4efeb694',
            name: 'ROTHMANS BLUE',
            value: 153.6,
            __typename: 'DataValueId',
          },
          {
            id: 'c115cb74-b9ab-47d0-a331-eb8f66957114',
            name: 'BANANAS CAVENDISH X LGE',
            value: 151.72,
            __typename: 'DataValueId',
          },
          {
            id: '1d879b40-ab02-448e-99f6-92846df840dc',
            name: 'SMIRNOFF VODKA RED',
            value: 141.96,
            __typename: 'DataValueId',
          },
          {
            id: 'b122ab84-46a0-4e6c-a2df-996e733e3dc8',
            name: 'F/COUNTRY CHICKEN BREAST',
            value: 137.73,
            __typename: 'DataValueId',
          },
          {
            id: '83781d76-decb-4335-9169-9ae117d63603',
            name: 'LEAFY PATCH BABY SPINACH',
            value: 134.55,
            __typename: 'DataValueId',
          },
          {
            id: '7891a61c-257a-4135-b775-63e9165cf868',
            name: 'CHAMPION LEGENDARY RUBY',
            value: 122.5,
            __typename: 'DataValueId',
          },
          {
            id: 'cb7439db-3819-4e3c-8e1f-74c4984e7dcc',
            name: 'F/COUNTRY BEEF MINCE PREM',
            value: 121.39,
            __typename: 'DataValueId',
          },
          {
            id: '030b3a68-1513-47f3-9f8f-eccd8c64d444',
            name: 'AVOCADO',
            value: 102.09,
            __typename: 'DataValueId',
          },
          {
            id: '579644d4-867a-4095-ba23-c47f821391a7',
            name: 'CHAMPION LEGENDARY RUBY',
            value: 100.5,
            __typename: 'DataValueId',
          },
          {
            id: '69964dd6-d6ea-4a0a-b878-a7d99d0a603f',
            name: 'WINFIELD ORIGINAL BLUE',
            value: 86.8,
            __typename: 'DataValueId',
          },
        ],
        __typename: 'HeartbeatChartBarPaginatorType',
      },
    },
  },
};
